const contact = [


    {
        nombre: 'En catálogo',
        telefono: 543875683101,
        email: 'info@encatalogo.com',
        direccion: 'Salta, Argentina',
        instagram: 'https://www.instagram.com/en_catalogo_web/',
        facebook: 'https://www.facebook.com/profile.php?id=61564988544861',

    }

]
export default contact