import IndexLayout from "../Layouts/IndexLayout";
import MainLayout from "../Layouts/MainLayout";
import { createBrowserRouter } from "react-router-dom";
export const router = createBrowserRouter([

    {
        path: "/",
        element: <IndexLayout />,

    },

    {
        path: "/",
        element: <MainLayout />,
        children: [

        ],
    },


]);
