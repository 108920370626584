import React from 'react'
import './Demo.css'
import Footer from '../../Components/Footer/Footer'
import BtnWhatsapp from '../../Components/BtnWhatsapp/BtnWhatsapp'
import HeroMain from '../../Components/HeroMain/HeroMain';
import Hero from '../../Components/Hero/Hero';
import About from '../../Components/About/About';
import Videos from '../../Components/Videos/Videos';
import ScrollDemos from '../../Components/ScrollDemos/ScrollDemos';

export default function Demo() {
    return (
        <section className='demo'>
            <Hero />
            <HeroMain />
            <About />
            <ScrollDemos />
            <Videos />
            <Footer />

            <BtnWhatsapp />
        </section>
    )
}
